<template>
  <div>
    <a-spin :indicator="indicator" :spinning="spinVisable">
      <a-row style="margin: 40px 0px 20px 0px ;">
      <a-col :span="13">
        <a-carousel arrows dots-class="slick-dots slick-thumb" style="margin-bottom: 50px;">
          <a slot="customPaging" slot-scope="props">
            <img :src="getImgUrl(props.i)" />
          </a>
          <div v-for="(item, index) in housePic" :key="index">
            <img :src="item+'?x-oss-process=image/resize,l_1000'" />
          </div>
        </a-carousel>
      </a-col>
      <a-col :span="11">
        <div style="margin-bottom: 8px;">
          <span style="
                                font-weight: bold;
                                font-size: 28px;
                                font-family: Arial;
                                color: #ff0214;
                                ">
            价格面议
          </span>
        </div>
        <div>
          <span class="" style="
            font-size: 24px;
            margin: 4px 0px;
            font-weight:bold;
            color: black;
            font-family: Helvetica;
            display: inline-block;
            ">
            {{ asset.address }}
          </span>

          <span style="background-color: #f23a3a; border-radius: 5px;height: 16px; margin-left: 10px;">
            <span
              style="font-weight:bold;font-size:14px;font-family: Microsoft YaHei;color: #f7f7f7;margin:4px;display: inline-block;">火热招租</span>
          </span>
        </div>
        <div style="margin: 10px 0px 0px 0px;">
          <div style="
                      background-color: #f1f5f8;
                      color: #819aae;
                      display: inline-block;
                      padding: 2px 5px;
                      border-radius: 3px;
                      ">
            国企认证
          </div>
          <div style="
                      background-color: #f1f5f8;
                      color: #819aae;
                      display: inline-block;
                      padding: 2px 5px;
                      border-radius: 3px;
                      margin: 4px 6px;
                      ">
            自营房源
          </div>
          <div style="
                      background-color: #f1f5f8;
                      color: #819aae;
                      display: inline-block;
                      padding: 2px 5px;
                      border-radius: 3px;
                      margin: 4px 6px;
                      ">
            {{ asset.houseDescribe }}
          </div>
          <div style="
                      background-color: #f1f5f8;
                      color: #819aae;
                      display: inline-block;
                      padding: 2px 5px;
                      border-radius: 3px;
                      margin: 4px 6px;
                      ">
            无中介费
          </div>
          <div style="
                      background-color: #f1f5f8;
                      color: #819aae;
                      display: inline-block;
                      padding: 2px 5px;
                      border-radius: 3px;
                      margin: 4px 6px;
                      ">
            免租期面议
          </div>
          <div style="
                      background-color: #f1f5f8;
                      color: #819aae;
                      display: inline-block;
                      padding: 2px 5px;
                      border-radius: 3px;
                      margin: 4px 6px;
                      ">
            提前预约随时可看
          </div>
        </div>

        <div style="width: 78%;margin-top: -6px;">
          <a-divider />
        </div>
        <div class="blog-comment row justify-content-between align-items-center">
          <div class="col-md-6" style="margin-bottom: 12px;">
            <span style="font-size:18px; color: #acabbd;font-family: Microsoft YaHei;">
              出租面积：
            </span>
            <span style="font-size:18px; color: black;font-family: Microsoft YaHei;">
              {{ asset.rentableSpace }}㎡
            </span>
          </div>
          <div class="col-md-6" style="margin-bottom: 12px;">
            <span style="font-size:18px; color: #acabbd;font-family: Microsoft YaHei;">
              房屋属性：
            </span>
            <span style="font-size:18px;color: black;font-family: Microsoft YaHei;">

              {{ asset.houseType==1?
                  "商铺":asset.houseType ==2?
                  "写字楼":asset.houseType ==3?
                  "综合体":asset.houseType ==4?
                  "厂房":asset.houseType ==5?"其他商业":"居住"
              }}
            </span>
          </div>
          <div class="col-md-12" style="margin-bottom: 12px;">
            <span style="font-size:18px; color: #acabbd;font-family: Microsoft YaHei;">
              出租方式：
            </span>
            <span style="font-size:18px;color: black;font-family: Microsoft YaHei;">
              整租/分租
            </span>
          </div>
          <div class="col-md-6" style="margin-bottom: 12px;">
            <span style="font-size:18px; color: #acabbd;font-family: Microsoft YaHei;">
              周边公共交通：
            </span>
            <span style="font-size:18px; color: black;font-family: Microsoft YaHei;">
              {{ asset.publicTransportion }}
            </span>
          </div>
          <div class="col-md-6" style="margin-bottom: 12px;">
            <span style="font-size:18px; color: #acabbd;font-family: Microsoft YaHei;">
              装修现状：
            </span>
            <span style="font-size:18px;color: black;font-family: Microsoft YaHei;">
              {{ asset.decoration }}
            </span>
          </div>
          <div style="width: 78%;margin-top: -10px;">
            <a-divider />
          </div>
        </div>
        <div class="blog-comment row justify-content-between align-items-center">
          <div class="col-md-6">
            <div style="margin-bottom: 12px; display: flex; align-items: center;">
              <a-avatar
                src="https://gxscloud.oss-cn-shanghai.aliyuncs.com/efind/backgroundImage/servicePoint/cb6c5bf66ca92732044bcc1a96d5d41.jpg"
                shape="square" :size="65" />
              <span style="display: flex; flex-direction: column;">
                <div class="" style="
                  font-size: 18px;
                  margin: 4px 20px;
                  font-weight:bold;
                  color: #101d37;
                  font-family: Helvetica;
                  display: inline-block;
                ">
                  {{ asset.servicePoint }}
                </div>
                <div class="" style="
                  font-size: 16px;
                  margin: 4px 20px;
                  color: #9399a5;
                  font-family: Helvetica;
                  display: inline-block;
                ">
                  <div v-if="asset.placeTown ==11">
                    <img  src="@/assets/location.png" alt="" style="height: 18px;margin-bottom: 2px;" />上海·市区房源
                  </div>
                  <div v-else>
                    <img  src="@/assets/location.png" alt="" style="height: 18px;margin-bottom: 2px;" />上海·金山
                  </div>

                  
                </div>
              </span>
            </div>
            <div style="
                      padding: 10px 16px;
                      font-size: 16px;
                      box-sizing: border-box;
                      font-family: PingFangSC- Semibold;
                      color: #fff;
                      font-weight: 700;
                      background: #3072f6;
                      border-radius: 2px;
                      text-align: center;
                      display: inline-block;
                      vertical-align: middle;
                      margin-right: 20px;
                      ">
              <img src="@/assets/phone.png" alt="" style="height: 18px;" /> 联系我们 {{ asset.serviceLandline }}
            </div>
          </div>

          <div class="col-md-6" style="margin-bottom: 12px;">
            <img src="@/assets/efind.png" alt="" style="width:  30% ;" />
            <div style="margin-top: 6px;margin-left: 4px;">
              关注e指找房小程序
            </div>
            <div style="margin-top: 6px;margin-left: 8px;">
              掌握一手房源咨询
            </div>
          </div>
        </div>

      </a-col>
    </a-row>

    <div style="display: flex;
      justify-content: center; /* 水平居中 */
      align-items: center;     /* 垂直居中 */">
      <a-card style="width:84%;" :tab-list="tabListNoTitle" :active-tab-key="noTitleKey" :bordered="false"
        :headStyle="{ backgroundColor: '#fafafa' }" :bodyStyle="{ backgroundColor: '#fafafa' }">
        <div v-if="noTitleKey === 'article'" class="map">
          <div id="container" class="map">
          </div>
        </div>
      </a-card>
    </div>
    </a-spin>
    

  </div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import { getHouseDetail } from "@/request/api";
export default {
  props: ['assetId'],
  data() {
    return {
      spinVisable:true,
      indicator: <a-icon type="loading" style="font-size: 50px" spin />,
      map: null,
      markerList: [],
      clusterData: null,
      tabList: [
        {
          key: 'tab1',
          // tab: 'tab1',
          scopedSlots: { tab: 'customRender' },
        },
        {
          key: 'tab2',
          tab: 'tab2',
        },
      ],
      contentList: {
        tab1: 'content1',
        tab2: 'content2',
      },
      tabListNoTitle: [
        {
          key: 'article',
          tab: '位置周边',
        },
        // {
        //   key: 'article2',
        //   tab: '交易条件',
        // },
      ],
      key: 'tab1',
      noTitleKey: 'article',
      asset: {},
      housePic:[]
    };
  },
  mounted() {
    getHouseDetail(
      { params: { userId: -1, assetId: this.assetId } }
    ).then((res) => {
      this.asset = res.data.data;
      this.housePic.push(...this.asset.housePlanformUrl)
      this.housePic.push(...this.asset.housePic)
      this.housePic = this.housePic.slice(0, 11)
      this.initMap()
      this.spinVisable=false
    });
  },

  methods: {
    initMap() {
      AMapLoader.load({
        key: "5ae01332406925a167770eda0fb8803d",// 申请好的Web端开发者Key，首次调用 load 时必填
        version: "1.4.15",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.MarkerClusterer'],// 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map("container", {  //设置地图容器id
          // viewMode:"3D",    //是否为3D地图模式
          zoom: 17,           //初始化地图级别
          center: [this.asset.longitude, this.asset.latitude], //初始化地图中心点位置
        });
        // 构造矢量圆形
        this.markerClusterer()

      }).catch(e => {
        console.log(e);
      })

    },

    markerClusterer() {
      var markers = []
      var cluster
      var data = this.asset

      var _that = this

      var locationContent = ''
      var assetAttributes = ''
      locationContent = `<div><img style="width:40px;height:45px"  src="${require('@/assets/locationMap.png')}"></img></div>`
      assetAttributes = "自有资产"

      // 海量生成marker点
      var marker = new AMap.Marker({
        position: new AMap.LngLat(data.longitude, data.latitude),
        title: data.title,
        content: locationContent,
        offset: new AMap.Pixel(-15, -15),
      });

      var content = `<div style="width:100%;height:100%;display: flex; flex-direction: column;">
          <div style="width:100%;height:100%;display: flex; flex-direction: row;">
              <div style="font-weight:bold;font-family: Microsoft YaHei;font-size:17px;color: #484848;">${data.address}</div>
              <a style="font-weight:bold;font-family: Microsoft YaHei;font-size:12px;margin:4px;">
              </a>
          </div>
          <div style="width:100%;height:100%;display: flex; flex-direction: row;margin: 4px 0px 2px 0px">
              <div style="font-family: Microsoft YaHei;font-size:14px;color: #484848;">房屋属性：</div>
              <div>${data.houseDescribe==1?
                  "商铺":data.houseDescribe ==2?
                  "写字楼":data.houseDescribe ==3?
                  "综合体":data.houseDescribe ==4?
                  "厂房":data.houseDescribe ==5?"其他商业":"居住"}</div>
          </div>

          <div style="width:100%;height:100%;display: flex; flex-direction: row;margin: 2px 0px">
              <div style="font-family: Microsoft YaHei;font-size:14px;color: #484848;">装修现状：</div>
              <div>简装</div>
          </div>
          
          <div style="width:100%;height:100%;display: flex; flex-direction: row;margin: 2px 0px">
              <div style="font-family: Microsoft YaHei;font-size:14px;color: #484848;">周边公共交通：</div>
              <div >公交1661路、公交1661路</div>
          </div>
          
          </div>


          `;
      content.replace(/undefined/, "");

      // 窗体信息
      let infoWindow = new AMap.InfoWindow({
        content: content, //使用默认信息窗体框样式，显示信息内容
        offset: new AMap.Pixel(5, -10),
      });

      //监听marker的鼠标划入事件
      AMap.event.addListener(marker, "mouseover", function (e) {
        infoWindow.open(
          _that.map,
          // 窗口信息的位置
          marker.getPosition(data.lnt, data.lat)
        );
      });

      markers.push(marker);

      this.markerList = markers
      //使用renderClusterMarker属性实现聚合点的完全自定义绘制
      var count = markers.length;
      var _renderClusterMarker = function (context) {
        var factor = Math.pow(context.count / count, 1 / 18);
        var div = document.createElement("div");
        var Hue = 180 - factor * 180;
        var bgColor = "hsla(" + Hue + ",100%,50%,0.7)";
        var fontColor = "hsla(" + Hue + ",100%,20%,1)";
        var borderColor = "hsla(" + Hue + ",100%,40%,1)";
        var shadowColor = "hsla(" + Hue + ",100%,50%,1)";
        div.style.backgroundColor = bgColor;
        var size = Math.round(30 + Math.pow(context.count / count, 1 / 5) * 20);
        div.style.width = div.style.height = size + "px";
        div.style.border = "solid 1px " + borderColor;
        div.style.borderRadius = size / 2 + "px";
        div.style.boxShadow = "0 0 1px " + shadowColor;
        div.innerHTML = context.count;
        div.style.lineHeight = size + "px";
        div.style.color = fontColor;
        div.style.fontSize = "14px";
        div.style.textAlign = "center";
        context.marker.setOffset(new AMap.Pixel(-size / 2, -size / 2));
        context.marker.setContent(div);
      };

      if (this.cluster != null) {
        this.cluster.setMap(null);
      }

      // 点聚合样式
      cluster = new AMap.MarkerClusterer(this.map, markers, {
        gridSize: 45,
        minClusterSize: 25,
        renderClusterMarker: _renderClusterMarker
      });
      this.cluster = cluster



    }

    ,
    getImgUrl(i) {
      var img = this.housePic[i]
      return img+"?x-oss-process=image/resize,l_1000";
    },



  },
};
</script>
<style scoped>
/* For demo */
.ant-carousel>>>.slick-dots {
  height: auto;
}

.ant-carousel>>>.slick-slide img {
  border-radius: 12px;
  height: 500px;
  border: 5px solid #fff;
  display: block;
  margin: auto;
  max-width: 80%;
}

.ant-carousel>>>.slick-thumb {
  bottom: -45px;
}

.ant-carousel>>>.slick-thumb li {
  width: 60px;
  height: 45px;
}

.ant-carousel>>>.slick-thumb li img {
  border-radius: 3px;
  width: 100%;
  height: 100%;
  filter: grayscale(100%);
}

.ant-carousel>>>.slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
</style>

<style lang="less" scope>
.map {
  #container {
    border-radius: 4px;
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 700px;
  }

  .amap-logo {
    display: none;
    opacity: 0 !important;
  }

  .amap-copyright {
    opacity: 0;
  }
}
</style>
